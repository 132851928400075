import { FC } from "react";
import ContainerDividerSpanFirst from "@components/dividers/container-divider-span-first";
import OfficeWork from "@images/ready.webp";
import { PagePropsBasicType } from "../../../@types/types-body";
import { BoxImageSecondImageParagraph } from "@components/boxes/box-image-second-image-paragraph";

const WhyChooseUs: FC<PagePropsBasicType> = ({ namespace }) => {
  return (
    <section id="why-us" className="relative my-24">
      <div className="container mx-auto">
        <ContainerDividerSpanFirst
          id={"home-1"}
          title={"why_us.title"}
          text={"why_us.text"}
          span={"why_us.span"}
          namespace={namespace}
          subtitle={"why_us.subtitle"}
        />
        <BoxImageSecondImageParagraph
          namespace={namespace}
          key={"section-wordpress-benefit-intro"}
          object={"why_us.text_object"}
          image={OfficeWork}
          section={"why_us.title"}
        />
      </div>
    </section>
  );
};
export default WhyChooseUs;
