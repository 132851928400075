import { useTranslation } from "next-i18next";
import { FC } from "react";
import Image from "next/image";

import {
  BoxImageFirstListType,
  i18translateType,
} from "../../@types/types-body";

export const BoxImageSecondImageParagraph: FC<BoxImageFirstListType> = ({
  namespace,
  object,
  image,
  section,
}) => {
  const { t }: i18translateType = useTranslation(namespace);

  return (
    <div className="container mx-auto">
      <div className="grid grid-cols-12 grid-flow-row-dense lg:grid-flow-col-dense gap-1 content-between">
        <div className="col-span-12 lg:col-span-6 order-last md:order-first md:px-12 text-justify">
          <ul className="list-disc list-outside">
            {Object.keys(t(`${object}`, { returnObjects: true })).map(
              (objectValue, index) => (
                <p className="mb-1" key={`${object}-${objectValue}-${index}`}>
                  {t(`${object}.${objectValue}`)}
                </p>
              )
            )}
          </ul>
        </div>
        <div className="col-span-12 lg:col-span-6 min-h-[200px] lg:min-h-[300px] mb-8 lg:mb-0 relative rounded lg:shadow-2xl rounded-lg -mx-[15px]">
          <Image
            src={image}
            fill={false}
            alt={section}
            className="w-full h-full"
          />
        </div>
      </div>
    </div>
  );
};
